import { Box, Button, Container, Flex, Show, Stack, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import theme from "../design/theme";
import { Link } from "react-scroll";

const HeadingBanner = () => {

    return (
        <Box bgColor="whiteAlpha.400">
            <Show above="md">
                <Box minHeight="25rem" width="100%" style={{ display: 'flex'}} alignItems="center" justifyContent="center" flexDirection="column">
                    <Text fontSize="6xl" fontWeight="extrabold">
                        Reviews that work for you
                    </Text>
                    <Text fontSize="xl"  color="gray.500">
                        Get more reviews, build trust, and boost sales with our easy-to-use review app.
                    </Text>
                    <Stack margin="2rem">
                        <Link to="subscribe" smooth={true}>
                            <Button colorScheme="messenger" size="lg" padding="2rem">Try Free now</Button>
                        </Link>
                    </Stack>
                </Box>
            </Show>
            <Show below="sm">
                <Container  minHeight="15rem">
                    <Text>
                        Sub Heading
                    </Text>
                </Container>
            </Show>
        </Box>
    );
};

export default HeadingBanner;