import { Box, Button, Checkbox, Container, Flex, Heading, Input, Show, Text, useTheme, useToast } from "@chakra-ui/react";
import React from "react";
import theme from "../design/theme";
import useSubscription from "../hook/useSubscription";
import { useForm } from "react-hook-form";
import { emailRegex } from "../util/regexUtil";
import { ErrorMessage } from "@hookform/error-message";

const Subscription = () => {


    const {
        register,
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
        reset
    } = useForm({
        mode: "onSubmit"
    });


    const { 
        submitSubscription,
        submitInProgress,
    } = useSubscription();


    const onSubmit = (values) => {
        submitSubscription(values, reset);
    };

    return (
        <Box bgColor="gray.10" id="subscribe">
            <Show above="md">
                <form onSubmit={handleSubmit(onSubmit)} >
                    <Box minHeight="20rem" width="100%" style={{ display: 'flex'}} alignItems="start" justifyContent="start" flexDirection="column" padding="4rem" gap="2rem">
                        <Text fontSize="3xl" color="blue.300">Subscribe to our newsletter</Text>
                        {/* <Text fontSize="s">
                            Get the latest news and updates about our product, delivered straight to your inbox.
                        </Text> */}
                            <Input
                                type="email"
                                name="email"
                                placeholder="Enter your email address"
                                variant="flushed"
                                {
                                    ...register("email", {
                                        pattern: emailRegex,
                                        required: "This is required"
                                    })
                                }
                                fontSize="2rem"
                            />
                        <ErrorMessage errors={errors} name="email" style={{ fontSize: '14px'}} as={<Text />}/>
                        <Flex alignItems="center" justifyContent="center">
                            <Checkbox
                                name="subscribe_to_notifications"
                                {
                                    ...register("opt_in", {
                                        required: "this is required",
                                    })
                                }
                                colorScheme="messenger"
                                size="lg"
                            >
                                I accept to recieve notification about review factor products and updates
                            </Checkbox>
                        </Flex>
                        <Button
                            type="submit"
                            isLoading={isSubmitting || submitInProgress}
                            colorScheme="messenger"
                            size="lg"
                            padding="2rem"
                            fontSize="1.5rem"
                        >
                            Subscribe
                        </Button>
                    </Box>
                </form>
            </Show>
        </Box>
    );
};

export default Subscription;