import { ChakraProvider, Container } from "@chakra-ui/react";
import React from "react";
import Header from "./Header";
import theme from "../design/theme";
import HeadingBanner from "./HeadingBanner";
import AppDescription from "./AppDescription";
import Features from "./Features";
import EarlyBirdOffer from "./EarlyBirdOffer";
import Subscription from "./Subscription";
import PricingPlan from "./PricingPlan";
import FAQ from "./FAQ";
import AboutUs from "./AboutUs";
import Footer from "./Footer";

const ReviewWebsite = () => {
    return (
        <ChakraProvider theme={theme}>
            <Header />
            <Container maxW='80%'>
                <HeadingBanner />
                {/* <AppDescription /> */}
                <Features />
                {/* <EarlyBirdOffer /> */}
                <Subscription />
                {/* <PricingPlan /> */}
                {/* <FAQ /> */}
                {/* <AboutUs /> */}
                <Footer />
            </Container>
        </ChakraProvider>
    )
};

export default ReviewWebsite;