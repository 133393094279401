import { Box, Button, Card, Container, Flex, Image, Input, Show, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { emailRegex } from "../util/regexUtil";
import useDemoRequest from "../hook/useDemoRequest";
const Footer = () => {


    const {
        register,
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
        reset
    } = useForm({
        mode: "onSubmit"
    });

    const {
        submitDemoRequest,
        submitInProgress
    } = useDemoRequest()

    const onSubmit = (values) => {
        submitDemoRequest(values, reset)
    }

    return (
        <Box bgColor="gray.50" padding="4rem">
            <Show above="md">
                <Flex alignItems="center" justifyContent="flex-start" paddingTop="3rem" id="contactus" direction='column' gap="1rem">
                    <Text fontSize="4xl" fontWeight="bold">Contact us</Text>
                    <Text fontSize="xl" fontWeight="semibold" color="gray.500">Request a demo</Text>
                    <Flex alignItems="center" justifyContent="center" gap="0.5rem">
                        <Card borderRadius="lg" padding="2rem" flex="60% 0 0">
                            <Flex alignItems="center" justifyContent="center" direction="column">
                                <Image src="./customer support.png" alt="Customer Support" flex="80% 0 0"/>
                                <Flex alignItems="center" justify="center" padding="2rem">
                                    <Text fontSize="xl" fontWeight="bold">Reach Out!</Text>
                                </Flex>
                            </Flex>
                        </Card>
                        <Card borderRadius="lg" padding="4rem" flex="auto 1 0" height="100%">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Flex direction="column" alignItems="center" justifyContent="center" gap="4rem">
                                    <Input
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        variant="flushed"
                                        {
                                            ...register("name", {
                                                required: true
                                            })
                                        }
                                        fontSize="2rem"
                                    />
                                    <Input
                                        type="text"
                                        name="organization_name"
                                        placeholder="Organization Name"
                                        variant="flushed"
                                        {
                                            ...register("organization_name", {
                                                required: true
                                            })
                                        }
                                        fontSize="2rem"
                                    />
                                    <Input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        variant="flushed"
                                        {
                                            ...register("email", {
                                                required: true,
                                                pattern: emailRegex
                                            })
                                        }
                                        fontSize="2rem"
                                    />
                                    <Input
                                        type="number"
                                        name="phone_number"
                                        placeholder="Phone Number (Optional)"
                                        variant="flushed"
                                        {
                                            ...register("phone_number", {
                                                required: false
                                            })
                                        }
                                        fontSize="2rem"
                                    />
                                    <Button
                                        type="submit"
                                        isLoading={isSubmitting || submitInProgress}
                                        colorScheme="messenger"
                                        size="lg"
                                        padding="2rem"
                                        fontSize="1.5rem"
                                    >
                                        Submit
                                    </Button>

                                </Flex>
                            </form>
                        </Card>
                    </Flex>
                </Flex>
            </Show>
        </Box>
    );
};

export default Footer;