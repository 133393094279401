import React, { useState } from "react";
import { submitSubscriptionApi } from "../api/subscriptionApi";
import { useToast } from "@chakra-ui/react";

const useSubscription = () => {

    const [ submitInProgress, setSubmitInProgress ] = useState(false);
    const [ errorInSubmit, setErrorInSubmit ] = useState(false);

    const toast = useToast();

    const submitSubscription = async (values, reset) => {
        try {
            const { email, opt_in } = values || {};

            if (!opt_in) return;

            const req = {
                email,
                props: {}
            };
            setSubmitInProgress(true);
            const res = await submitSubscriptionApi({ req });
            setSubmitInProgress(false);
            setErrorInSubmit(false);
            console.log(`reseting form`, reset);
            reset({});

            toast({
                title: "Subscribed",
                description: "Watch out for our latest updates on your inbox!",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
        } catch (err) {
            setSubmitInProgress(false);
            setErrorInSubmit(true);

            const { response: { data = {} } = {}} = err || {};
            const { message = "Failed to subscribe"} = data;

            toast({
                title: `${message}`,
                description: "Please reach out to our support if not subscribed already.",
                status: "info",
                duration: 5000,
                isClosable: true,
              })
        }
    };

    return {
        submitSubscription,
        submitInProgress,
        errorInSubmit
    };
}

export default useSubscription;