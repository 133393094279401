import { useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { submitDemoRequestApi } from "../api/customerDemoApi";

const useDemoRequest = (props) => {

    const [ submitInProgress, setSubmitInProgress ] = useState(false);
    const [ errorInSubmit, setErrorInSubmit ] = useState(false);

    const toast = useToast();

    const submitDemoRequest = async (values, reset) => {
        try {
            const { name, organization_name, email, phone_number } = values || {};

            let req = {
                name,
                organization_name,
                email
            };

            if (phone_number) {
                req = {
                    ...req,
                    phone_number
                };
            }

            setSubmitInProgress(true);
            const res = await submitDemoRequestApi({ req });
            setSubmitInProgress(false);
            setErrorInSubmit(false);

            toast({
                title: "Demo Requested",
                description: "Thanks for your interest, please check out your mail for demo details.",
                status: "success",
                duration: 5000,
                isClosable: true
            });
            reset();
        } catch(err) {
            setSubmitInProgress(false);
            const { message = "Error while submitting request" } = err?.response?.data || {};
            setErrorInSubmit(true);
            console.log(err);

            toast({
                title: `${message}`,
                description: "Please reach out to support",
                status: "info",
                duration: 5000,
                isClosable: true
            })
        }
    };


    return {
        submitDemoRequest,
        submitInProgress,
        errorInSubmit
    };
}

export default useDemoRequest;