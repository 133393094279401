import { Box, Button, Container, Flex, Icon, LinkBox, Show, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { MdOutlineSegment } from "react-icons/md";
import theme from "../design/theme";
import { Link } from "react-scroll";

const Header = () => {

    const theme = useTheme();
    return (
        <Box>
            <Show above="md">
                <Box backgroundColor="whiteAlpha.700" height="6rem">
                    <Flex alignItems="center" justifyContent="space-between" height="100%" margin="0 5rem">
                        
                        {/* <Flex flex="0 0 10%"  justifyContent="flex-start">
                            <Icon as={MdOutlineSegment} boxSize="10"/>
                        </Flex> */}
                        <Box flex="0 0 auto">
                            <Flex justifyContent="flex-start" alignItems="center">
                                {/* <Text fontWeight="extrabold" fontSize="logo">Review Factor</Text> */}
                                <Box className="desktop-logo-container">
                                    <Box className="sprite-desktop-logo"></Box>
                                </Box>
                            </Flex>
                        </Box>
                        <Flex flex="0 0 40%" justifyContent="flex-start" gap="4rem" >
                            <Link to="features" smooth={true}>
                                <Text fontSize="md" color="pink.600" fontWeight="bold">
                                    Features
                                </Text>
                            </Link>
                            {/* <Link to="pricing" smooth={true}>
                                <Text fontSize="md" color="pink.600" fontWeight="bold">
                                    Pricing
                                </Text>
                            </Link> */}
                            <Link to="contactus" smooth={true}>
                                <Text fontSize="md" color="pink.600" fontWeight="bold">
                                    Contact Us
                                </Text>
                            </Link>
                        </Flex>
                        <Flex flex="0 0 5%" justifyContent="flex-end">
                            <Button variant="ghost">
                                <Link to="subscribe" smooth={true}>
                                    <Text fontSize="s" color="pink.600">
                                        subscribe
                                    </Text>
                                </Link>
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
            </Show>
            <Show above="sm">
                <Show below="md">
                    <Flex backgroundColor="whiteAlpha.700" height="4rem" justifyContent="center">
                        <Flex alignItems="center" justifyContent="space-between" height="100%" margin="0 5rem">
                            
                            {/* <Flex flex="0 0 10%"  justifyContent="flex-start">
                                <Icon as={MdOutlineSegment} boxSize="10"/>
                            </Flex> */}
                            <Box flex="1">
                                <Flex justifyContent="flex-start" alignItems="center">
                                    {/* <Text fontWeight="extrabold" fontSize="logo">Review Factor</Text> */}
                                    <Box className="md-logo-container">
                                        <Box className="sprite-md-logo"></Box>
                                    </Box>
                                </Flex>
                            </Box>
                            {/* <Flex flex="0 0 5%" justifyContent="flex-end">
                                <Button variant="ghost">
                                    <Link to="subscribe" smooth={true}>
                                        <Text fontSize="s" color="pink.600">
                                            subscribe
                                        </Text>
                                    </Link>
                                </Button>
                            </Flex> */}
                        </Flex>
                    </Flex>
                </Show>
            </Show>
            <Show below="sm">
                <Flex backgroundColor="whiteAlpha.700" height="4rem" justifyContent="center">
                    <Flex alignItems="center" justifyContent="space-between" height="100%" margin="0 5rem">
                        
                        {/* <Flex flex="0 0 10%"  justifyContent="flex-start">
                            <Icon as={MdOutlineSegment} boxSize="10"/>
                        </Flex> */}
                        <Box flex="1">
                            <Flex justifyContent="flex-start" alignItems="center">
                                {/* <Text fontWeight="extrabold" fontSize="logo">Review Factor</Text> */}
                                <Box className="sm-logo-container">
                                    <Box className="sprite-sm-logo"></Box>
                                </Box>
                            </Flex>
                        </Box>
                        {/* <Flex flex="0 0 5%" justifyContent="flex-end">
                            <Button variant="ghost">
                                <Link to="subscribe" smooth={true}>
                                    <Text fontSize="s" color="pink.600">
                                        subscribe
                                    </Text>
                                </Link>
                            </Button>
                        </Flex> */}
                    </Flex>
                </Flex>
            </Show>
        </Box>
    )
};

export default Header;