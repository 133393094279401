import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
    base: '0em',
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
};

const colors = {
    transparent: "transparent",
    black: "#000",
    white: {
        "0": "#fff",
        "100": "#ffa"
    },

};

const fontSizes = {
    s: "1rem",
    md: "1.2rem",
    xl: "2rem",
    "3xl": "3.5rem",
    "4xl": "5rem",
    logo: '1.75rem'
};

const theme = extendTheme({ breakpoints, fontSizes });

export default theme;