import { Box, Container, Flex, Image, Show, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import theme from "../design/theme";

const Features = () => {

    return (
        <Box bgColor="yellow.50" id="features" padding="4rem 0rem">
            <Show above="md">
                <Box minHeight="40rem" width="100%" style={{ display: 'flex'}} alignItems="center" justifyContent="flex-start" flexDirection="column">
                    <Text fontSize="3xl" fontWeight="extrabold">
                        Collect and display reviews all in one solution
                    </Text>
                    <Flex width="100%"  padding="2rem" gap="20" marginTop="4rem">
                        <Flex flex="0 0.5 35%" alignItems="center" justifyContent="center">
                            <Image src="https://review-widget-assets.s3.amazonaws.com/email+requests.webp" boxSize="sm" borderRadius="2xl" height="15rem"/>
                        </Flex>
                        <Flex flex="1 0.5 auto" alignItems="start" justifyContent="center" textAlign="left" direction="column" padding="1rem" gap="0.5rem">
                            <Text fontSize="xl" color="blue.300" fontWeight="bold">Seamless Review Requests</Text>
                            {/* <Text fontSize="3xl" color="blackAlpha.900" fontWeight="bolder"></Text> */}
                            <Text fontSize="md">
                                Easily send email review requests to your customers with our app. Users can submit reviews from any device, making it simple and convenient for them to share their feedback.
                            </Text>
                        </Flex>
                    </Flex>
                    {/* <Flex width="100%"  padding="2rem" gap="20" flexDirection="row-reverse" marginTop="4rem">
                        <Flex flex="0 0.5 40%" backgroundColor="green.200" height="15rem" borderRadius="2rem" alignItems="center" justifyContent="center">
                            <Image />
                            Image
                        </Flex>
                        <Flex flex="1 0.5 auto" alignItems="center" justifyContent="center" textAlign="right">
                            <Text fontSize="md">
                            Easily send email review requests to your customers with our app. Users can submit reviews from any device, making it simple and convenient for them to share their feedback.
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex width="100%"  padding="2rem" gap="20" marginTop="4rem">
                        <Flex flex="0 0.5 40%" backgroundColor="green.200" height="15rem" borderRadius="2rem" alignItems="center" justifyContent="center">
                            <Image />
                            Image
                        </Flex>
                        <Flex flex="1 0.5 auto" alignItems="center" justifyContent="center" textAlign="left">
                            <Text fontSize="md">
                            Easily send email review requests to your customers with our app. Users can submit reviews from any device, making it simple and convenient for them to share their feedback.
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex width="100%"  padding="2rem" gap="20" flexDirection="row-reverse" marginTop="4rem">
                        <Flex flex="0 0.5 40%" backgroundColor="green.200" height="15rem" borderRadius="2rem" alignItems="center" justifyContent="center">
                            <Image />
                            Image
                        </Flex>
                        <Flex flex="1 0.5 auto" alignItems="center" justifyContent="center" textAlign="right">
                            <Text fontSize="md">
                            Easily send email review requests to your customers with our app. Users can submit reviews from any device, making it simple and convenient for them to share their feedback.
                            </Text>
                        </Flex>
                    </Flex> */}
                </Box>
            </Show>
        </Box>
    );
};

export default Features;